:root{
    --block-spacing-vertical: calc(var(--spacing))
}

div[id='root'] {
    margin: 0 !important;
    box-sizing: border-box;
    padding: 0;
    overscroll-behavior: none;
}

.topNav {
    height: 102px;
}

.topNav nav {
    position: fixed;
    background-color: var(--background-color);
    z-index: 100;
}

.brand { 
    font-size: 1.5em;
}

.beta {
    font-size: .9em;
}

.div[class='modules noscroll'] div {
    overflow: hidden !important;
}

section[class='modules']>div[class='grid'] {
    grid-template-columns: 50% 50%;
}

.card-table {
    display: block;
    --block-spacing-vertical: calc(var(--spacing) * 1.5);
}

.card-table main {
    max-height: 300px;
    overflow-y: auto;
}

article[class='card-table'] figure {
    min-width: 500px;
    max-width: auto;
}

ul[role="listbox"] {
    height: 15em;
    overflow: scroll;
}

ul[role="listbox"] li a[role='button'] {
    margin-left: .1rem;
    margin-right: .1rem;
    margin-top: .1rem;
    margin-bottom: .1rem;
}

ul[id='linked-list'] {
    border-radius: var(--border-radius);
    background-color: var(--form-element-background-color);
    padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
}

ul[id='linked-list'] li {
    padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
    border-bottom: var(--border-width) solid var(--form-element-border-color);
    list-style-type: none;
}

ul[id='linked-list'] li a {
    text-decoration: none;
    width: 100%;
}

dl {
    border: 1px solid var(--primary);
    padding: 0.5em;  
}

dt {
    display: flex; 
    float: left;
    flex-direction: column;  
    width: 50%;
    border: 1px solid var(--secondary);
    padding: 0.5em
}

dt::after {
    display: table;
    clear: left;
}

dd {
    display: flex;
    flex-direction: column;
    width: 50%;
    border: 1px solid var(--secondary);
    padding: 0.5em;
    text-align: right;
}

input[type='reset'] {
    margin-bottom: 0px !important;
}

button[class='delete'] {
    background-color: var(--del-color);
    color: var(--contrast);
}

.input-btn-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.input-btn-group label {
    width: 50% !important;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.small-btn {
    margin: 0px !important;
    padding: 0.5em !important;
    width: 100% !important;
    height: fit-content !important;
    font-size: small !important;
}

input[class='small-input'] {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    height: calc(1rem * var(--line-height) + var(--form-element-spacing-vertical) + var(--border-width)*2) !important;
}

.noscroll {
    overflow: hidden !important;
}

main[class='container'] > div[class='container '] {
    overflow: auto;
}

main[class='container'] > div[class='container noscroll'] {
    overflow: hidden;
}

.drawer-container {
    width: 100%;
}

.drawer {
    width: 100%;
    height:100%;
    background: var(--card-background-color);
    position: fixed;
    top: 0;
    right: 0;
    padding: 1em;
    z-index: 1000;
    box-shadow: 1px 0px 15px var(--primary); 
    transition: transform 3s ease-out;
    transform: translateX(0);
}

.drawer.right {
    top: 0;
    right: 0;
    transform: translateX(100%)
}

.drawer.in .right {
    transform: translateX(0)
}

.backdrop {
    visibility: hidden;
    opacity: 1;
    background: rgba(0,0,0, 0.5);
    transition: opacity 0.3s ease,
        visibility 0.3s ease;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    pointer-events: none;
    z-index: 0;
}

.drawer-container.is-open .backdrop {
    visibility: visible;
    opacity: 0;
    z-index: 0;
}

.drawer-content {
    padding-top: 0px !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
    width: 100%;
    height: 92vh !important;
    padding-bottom: 10rem;
    overflow-y: auto;
}

.drawer-card {
    display: block;
    width: 100% !important;
}

.drawer-side-card {
    width: 20% !important;
    height: 50% !important;
    position: fixed;
    float: right;
}

.drawer-header {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    gap: 0.5em;
}

.drawer-header h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.5em !important;
}

.drawer-footer {
    background-color: var(--card-background-color);
    border-top: var(--border-width) solid var(--form-element-border-color);
    position: fixed;
    display: flex;
    justify-content: space-between;
    gap: 0.5em;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    vertical-align: middle;
}

.drawer-footer h3, h4, h5 {
    margin-bottom: 0 !important;
    margin-left: 1.5em;
    margin-right: 1.5em;
}

div[id='doc-section'] {
    grid-template-columns: auto;
}
@media (min-width: 993px) {
    .drawer {
        width: 50%;
        padding: 1em;
    }
    .drawer-content {
        width: 100%;
    }
}
@media (max-width: 992px) {
    .closed-on-mobile aside {
        position: inherit;
    }
    .closed-on-mobile aside nav {
        width: 100%
    }
    .closed-on-mobile {
        width: 100%;
        transition: all 0.2s ease-in;
    }
    .drawer {
        width: 100%;
        padding: 1em;
    }
    .drawer-content {
        width: 100%;
    }
    .drawer-card {
        margin-bottom: 1em !important;
    }
    section[class='modules']>div[class='grid'] {
        grid-template-columns: auto;
        width: 100%;
    }
    div[id='doc-section'] {
        grid-template-columns: auto;
        width: 100%;
    }
    .content {
        width: 100%
    }
}




